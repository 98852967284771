<template>
  <div class="content chart-data">
    <div class="text-right mb-3">
      <span class="mr-2 fs-9">当前数据年份</span>
      <el-date-picker style="width: 300px !important" v-model="dataQuery.times" type="monthrange" range-separator="至"
        start-placeholder="开始月份" end-placeholder="结束月份">
      </el-date-picker>
    </div>
    <div class="mb-4 mt-4">
      <el-row>
        <el-col :span="12">
          <div class="amount-line-charts chart" ref="amount_line_charts"></div>
        </el-col>
        <el-col :span="12">
          <div class="amount-circle-charts chart" ref="amount_circle_charts"></div>
        </el-col>
        <el-col :span="24">
          <div class="search d-flex justify-content-end">
            <el-input v-model="company_name" class="!w-15" style="width: 200px;" /> <el-button type="primary"
              @click="fetchCustomerharts" class="mx-2">搜索</el-button>
          </div>
          <div class="amount-bar-charts chart" ref="amount_bar_charts"></div>
        </el-col>
        <el-col :span="24" class="d-flex flex-wrap">
          <div class="way_chart  chart" ref="way_line_charts" v-for="item in way_categories" :key="item.text"></div>
        </el-col>
        <el-col :span="24" class="d-flex flex-wrap">
          <div class="buy_chart  chart" ref="buy_line_charts"></div>
        </el-col>

        <el-col :span="24" class="d-flex flex-wrap">
          <div class="province_chart  chart" ref="province_charts"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import * as dayjs from "dayjs"
  export default {
    data() {
      return {
        loading: false,
        dataQuery: {
          times: [dayjs().startOf("year").toDate(), dayjs().toDate()],
        },
        company_name: "",
        amount_line_charts: "",
        amount_circle_charts: "",
        province_charts: "",
        province_options: {},
        amount_bar_charts: "",
        way_line_charts: "",
        way_list: [],

        way_categories: [
          {
            text: `年100天以上授课讲师排名`,
            range: [100, 9999]
          },
          {
            text: '年80-100天授课讲师排名',
            range: [80, 100]
          },
          {
            text: '年50-80天以上授课讲师排名',
            range: [50, 80]
          },
          {
            text: '年30-50天以上授课讲师排名',
            range: [0, 50]
          },
        ],
        amount_bar_options: {},
        way_line_options: {},
        amount_circle_options: {
          title: {
            text: '全年业绩完成图',
            left: 'center'
          },
          dataZoom: [
            {
              type: 'slider', // 滑动条型数据区域缩放组件
              start: 10, // 左边在10%的位置
              end: 60 // 右边在60%的位置
            },
            {
              type: 'inside', // 内置型数据区域缩放组件
              start: 10, // 左边在10%的位置
              end: 60 // 右边在60%的位置
            }
          ],
          // color: ['#fc8251', '#f9c956'],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: []
        },
        amount_line_options: {
          title: {
            text: "每月销售额",
            style: {
              fontSize: 12,
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
        },
        buy_options: {},
        buy_line_charts: ""
      };
    },
    props: {
      user_id: {
        type: String,
      },
      circle_data: {
        type: Object,
      },
    },

    watch: {
      user_id(val, oldval) {
        if (val) {
          this.fetchCharts();
          this.fetchCustomerharts()
          this.fetchWayharts()
          this.fetchBuyCharts()
        }
      },
    },

    methods: {
      getSeries() {

        let series = [];

        console.log(this.circle_data)
        if (this.circle_data.is_admin) {
          //总管理员
          const diff_count = this.circle_data.group_annual_goal - this.circle_data.year_received;
          series = [
            { value: this.circle_data.year_received, name: '已完成' },
            { value: diff_count, name: '未完成' }
          ];
        } else {
          // 小组情况
          if (this.circle_data.is_group_team) {
            const diff_count = this.circle_data.group_annual_goal - this.circle_data.year_received;
            series = this.circle_data.children.map(v => {
              return {
                value: v.year_received,
                name: v.realname
              }
            });
          }

          //单个用户
          if (this.circle_data.is_child) {
            const diff_count = this.circle_data.agent_annual_goal - this.circle_data.year_received;
            series = [
              { value: this.circle_data.year_received, name: '已完成' },
              { value: diff_count, name: '未完成' }
            ];
          }


        }

        return series;

      },
      setCircleData() {



        const series_data = this.getSeries()

        console.log(series_data)


        let series =
        {

          type: 'pie',
          radius: '50%',
          data: series_data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }


        this.$set(this.amount_circle_options, 'series', series)

      },
      async fetchCustomerharts() {
        const { data } = await this.$http.get("seller/customer_analyze/", {
          params: {
            user: this.user_id,
            times: this.dataQuery.times,
            company_name: this.company_name
          },
        });
        this.amount_bar_options = data.amount_bar_data;
        let amount_bar_charts = this.$echarts.init(this.$refs.amount_bar_charts);
        amount_bar_charts.setOption(this.amount_bar_options, true);
      },
      async fetchWayharts() {
        const { data } = await this.$http.get("seller/way_analyze/", {
          params: {
            user: this.user_id,
            times: this.dataQuery.times,
          },
        });
        this.way_list = data.way_list;
        this.setWayOptions();
        // let way_line_charts = this.$echarts.init(this.$refs.way_line_charts);
        // way_line_charts.setOption(this.way_line_options, true);
      },
      setWayOptions() {

        let baseOptions =
        {
          title: {
            text: 'World Population'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            orient: 'vertical',
            right: 'right'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World']
          },

          series: [
            {
              name: '2011',
              type: 'bar',
              data: [18203, 23489, 29034, 104970, 131744, 630230]
            },
          ],
        };


        const current_year = this.dataQuery.times[0]

        console.log(current_year, this.way_list)






        for (let i in this.$refs.way_line_charts) {
          let item = this.$refs.way_line_charts[i];

          const c = this.way_categories[i]



          let title = dayjs(current_year).format("YYYY")
          let prev_title = dayjs(current_year).subtract(1, "year").format("YYYY")



          let way_line_chart = this.$echarts.init(item);

          const data = this.way_list.filter(v => {
            const [min, max] = c.range;
            return v.year_total_time <= max && v.year_total_time > min;
          })

          const prev_data = this.way_list.filter(v => {
            const [min, max] = c.range;
            return v.prev_total_time <= max && v.prev_total_time > min;
          })
          const cur_names = data.concat(prev_data).filter(v => v.year_total_time > 0 && v.prev_total_time > 0).map(v => v.name)

          let names = Array.from(new Set(cur_names))
          console.log({
            names
          })
          if (names.length > 20) {
            baseOptions.dataZoom = [
              {
                type: 'slider', // 滑动条型数据区域缩放组件
                start: 10, // 左边在10%的位置
                end: 5, // 右边在60%的位置
                yAxisIndex: [0],
              },
              {
                type: 'inside', // 内置型数据区域缩放组件
                start: 10, // 左边在10%的位置
                end: 20, // 右边在60%的位置
                yAxisIndex: [0],
              }
            ];
          } else {
            delete baseOptions.dataZoom
          }
          baseOptions.title.text = title + c.text;
          baseOptions.yAxis.data = names;
          baseOptions.series[0] = {
            name: title,
            type: 'bar',
            data: names.map(n => {
              const year_item = data.find(v => v.name === n)
              return year_item?.year_total_time || 0;
            })
          }
          baseOptions.series[1] = {
            name: prev_title,
            type: 'bar',
            data: names.map(n => {
              const year_item = prev_data.find(v => v.name === n)
              return year_item?.prev_total_time || 0;
            })
          }



          if (data.length == 0 && prev_data.length == 0) {
            baseOptions.graphic = [
              {
                type: "text",
                left: "center",
                top: "center",
                style: {
                  text: "暂无数据",
                },
              },
            ]
          } else {
            delete baseOptions.graphic;
          }

          way_line_chart.setOption(baseOptions, true);

        }




      },
      async fetchCharts() {
        this.loading = true;
        const { data } = await this.$http.get("seller/data_analyze", {
          params: {
            user: this.user_id,
            times: this.dataQuery.times,
          },
        });

        this.amount_line_options = data.amount_line_data;
        this.setCircleData()
        this.initChart();
        this.loading = true;
      },
      initChart() {
        let amount_line_charts = this.$echarts.init(this.$refs.amount_line_charts);
        let amount_circle_charts = this.$echarts.init(this.$refs.amount_circle_charts);
        amount_line_charts.setOption(this.amount_line_options, true);
        amount_circle_charts.setOption(this.amount_circle_options, true);
      },
      async fetchBuyCharts() {
        const { data } = await this.$http.get("seller/buy_analyze", {
          params: {
            user: this.user_id,
            times: this.dataQuery.times,
          },
        });

        this.buy_options = data.buy_options;
        let buy_line_charts = this.$echarts.init(this.$refs.buy_line_charts);
        buy_line_charts.setOption(this.buy_options, true);
      },
      async fetchProvinceCharts() {
        const { data } = await this.$http.get("seller/province_analyze", {
          params: {
            user: this.user_id,
            times: this.dataQuery.times,
          },
        });

        this.province_options = data.province_options;
        let province_charts = this.$echarts.init(this.$refs.province_charts);
        province_charts.setOption(this.province_options, true);
      }
    },
    mounted() {
      this.fetchCharts();
      this.fetchCustomerharts();
      this.fetchWayharts()
      this.fetchBuyCharts()
      this.fetchProvinceCharts()
    },
  };
</script>

<style>
  .chart {
    width: 100%;
    height: 300px;
  }

  .amount-bar-charts {
    height: 800px;
  }

  .way_chart,
  .province_chart {
    width: 50%;
    height: 500px;
  }


  .province_chart {
    width: 100%;
    height: 500px;
  }

  .buy_chart {
    height: 500px;
  }

  .chart-data {
    height: 600px;
    overflow-y: auto;
  }
</style>
